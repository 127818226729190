export default async function getNameRoomScreen(): Promise<string> {
  let name = localStorage.getItem('user');
  function getQueryVariable(variable: string) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (pair[0] == variable) {
        return pair[1];
      }
    }
  }
  var type = getQueryVariable('type');

  if (name) return name;

  return new Promise((resolve, reject) => {
    const interval = setInterval(() => {
      if (type === 'RHNDPO7AYB') {
        name = localStorage.getItem('userPaciente');
      } else {
        name = localStorage.getItem('userPsicologo');
      }
      if (name) {
        clearInterval(interval);
        resolve(name);
      }
    }, 100);
  });
}
