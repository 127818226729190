import axios from 'axios';
import swal from 'sweetalert';

export default async function sum(a: number, b: number) {
  function getQueryVariable(variable: string) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (pair[0] == variable) {
        return pair[1];
      }
    }
  }
  var type = getQueryVariable('type');
  var url_string = window.location.href;
  var url = new URL(url_string);
  var timeCount = getQueryVariable('tempoConsulta');

  url_string = window.location.href;
  url = new URL(url_string);

  var tokenValidacao = url.pathname;

  if (!type || (type !== 'Y0PW9P0FPD' && type !== 'RHNDPO7AYB')) {
    swal({
      title: 'Sala não existe!',
      text: 'Você está acessando uma sala que não foi criada pelo Psicologia Viva.',
      icon: 'warning',
      closeOnClickOutside: false,
      closeOnEsc: false,
    }).then(function() {
      window.location.href = 'https://consultorioonline.psicologiaviva.com.br/login';
    });
  }

  tokenValidacao = tokenValidacao.replace('/room/', '');

  const res = await axios
    .get(
      'https://kong-api.psicologiaviva.com.br/sala/v1/informacoessala/' + tokenValidacao + '?type=' + type + '',
      {
        headers: {
          'x-api-key': '24127668e32af85aae70c4ab1fc4c52c6a78d800259e01175354498e04abf618',
          'Content-Type': 'application/json',
        },
      }
    )
    .then(response => {
      const urlParams = new URLSearchParams(window.location.search);
      const typeParam = urlParams.get('type');
      if (typeParam === 'RHNDPO7AYB') {
        window.localStorage.setItem('userPaciente', response.data.nomePaciente);
      } else {
        window.localStorage.setItem('userPsicologo', response.data.nomePsicologo);
      }
    })
    .catch(err => {
      if (err.response.data.messageKey == 'error.consultapassada') {
        swal({
          title: 'Consulta finalizada!',
          text: 'Você está acessando uma consulta que já terminou.',
          icon: 'warning',
          closeOnClickOutside: false,
          closeOnEsc: false,
        }).then(function() {
          window.location.href = 'https://consultorioonline.psicologiaviva.com.br/login';
        });
      }
      if (err.response.data.messageKey == 'error.tokennaoexiste') {
        swal({
          title: 'Sala não existe!',
          text: 'Você está acessando uma sala que não foi criada pelo Psicologia Viva.',
          icon: 'warning',
          closeOnClickOutside: false,
          closeOnEsc: false,
        }).then(function() {
          window.location.href = 'https://consultorioonline.psicologiaviva.com.br/login';
        });
      }

      if (err.response.data.messageKey == 'error.consultafutura') {
        swal({
          title: 'Consulta futura!',
          text: 'Você está acessando uma consulta futura, ainda não está na hora de sua consulta. Volte mais tarde.',
          icon: 'warning',
          closeOnClickOutside: false,
          closeOnEsc: false,
        }).then(function() {
          window.location.href = 'https://consultorioonline.psicologiaviva.com.br/login';
        });
      }

      if (err.response.data.messageKey == 'error.consultacancelada') {
        swal({
          title: 'Consulta cancelada!',
          text: 'Você está acessando uma consulta que foi cancelada, não é possível acessar essa sala.',
          icon: 'warning',
          closeOnClickOutside: false,
          closeOnEsc: false,
        }).then(function() {
          window.location.href = 'https://consultorioonline.psicologiaviva.com.br/login';
        });
      }
    });
}
